import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentModeComponent } from './common/paymentmode/paymentmode.component';
import { AuthGuardService } from './services/auth/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'payment-mode',
    pathMatch: 'full',
  },
  {
    path: 'payment-mode',
    component: PaymentModeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
