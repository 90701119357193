import { Component, OnInit, ViewChild } from "@angular/core";
import { ApiConfiguration } from "src/app/services/http/api-configuration";
import { ApiService } from "src/app/services/http/api.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RoutesRecognized,
} from "@angular/router";
import { AuthenticationCheckService } from "src/app/services/auth/authentication-check.service";
import { ToastClientService } from "src/app/services/toast/toast-client.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Location } from "@angular/common";
import { filter, pairwise } from "rxjs/operators";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { ComplexInnerSubscriber } from "rxjs/internal/innerSubscribe";

@Component({
  selector: "app-paymentmode",
  templateUrl: "./paymentmode.component.html",
  styleUrls: ["./paymentmode.component.scss"],
})
export class PaymentModeComponent implements OnInit {
  tempOptionModel;
  programs: any = [];
  errorMessage: Boolean = true;
  submitted = false;
  passwordSubmitted = false;
  hideClearDB: boolean = true;
  clearDBsubmit = false;

  paymentModeForm;
  paymentMode: any;
  url: any = "";
  customer_token: any = "";
  customerDetails: any;
  toasterMessage:any = ''
  ModalMessage: any = ''

  @ViewChild('failedModal', { static: false }) modal: any;

  constructor(
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private router: Router,
    private formBuilder: FormBuilder,
    private toast: ToastClientService,
    public modalService: NgbModal,
    public location: Location,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient
  ) {
    this.paymentModeForm = formBuilder.group({
      account_name: new FormControl(""),
      ifsc_code: new FormControl(""),
      account_num: new FormControl(""),
      UPI: new FormControl(""),
    });
  }
  isUPI: boolean = false;
  isNeft: boolean = false;
  selectPaymentMode(mode) {
    if (mode == "UPI") {
      this.paymentModeForm.reset();
      this.paymentMode = 2;
      this.isUPI = true;
      this.isNeft = false;
      this.paymentModeForm.get("UPI").setValidators([Validators.required,Validators.pattern('^[a-zA-Z0-9.\-_]{2,49}@[a-zA-Z._]{2,49}$')]);
      this.paymentModeForm.get("UPI").updateValueAndValidity();
      this.paymentModeForm.get("account_name").clearValidators();
      this.paymentModeForm.get("account_name").updateValueAndValidity();
      this.paymentModeForm.get("account_num").clearValidators();
      this.paymentModeForm.get("account_num").updateValueAndValidity();
      this.paymentModeForm.get("ifsc_code").clearValidators();
      this.paymentModeForm.get("ifsc_code").updateValueAndValidity();
    } else if (mode == "NEFT") {
      this.paymentMode = 1;
      this.isUPI = false;
      this.isNeft = true;
      this.paymentModeForm.get("account_name").setValidators(Validators.required);
      this.paymentModeForm.get("account_name").updateValueAndValidity();
      this.paymentModeForm.get("ifsc_code").setValidators([Validators.required,Validators.pattern('^[A-Z]{4}0[A-Z0-9]{6}$')]);
      this.paymentModeForm.get("ifsc_code").updateValueAndValidity();
      this.paymentModeForm.get("account_num").setValidators([Validators.required,Validators.pattern("^[0-9]{9,18}$")]);
      this.paymentModeForm.get("account_num").updateValueAndValidity();
      this.paymentModeForm.get("UPI").clearValidators();
      this.paymentModeForm.get("UPI").updateValueAndValidity();
    }
  }
  get db() {
    return this.paymentModeForm.controls;
  }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      console.log(res);
      this.url = res.ref;
      console.log(res.ref, typeof res.ref);
    });
    if (this.url != "") {
      this.getCustomer(this.url);
    }
  }
  // isValid(field): boolean {
  //   let isValid: boolean = false;

  //   // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
  //   if (this.loginForm.controls[field].touched == false) {
  //     isValid = true;
  //   }
  //   // If the field is touched and valid value, then it is considered as valid.
  //   else if (this.loginForm.controls[field].touched == true && this.loginForm.controls[field].valid == true) {
  //     isValid = true;
  //   }
  //   return isValid;
  // }
  getCustomer(token) {
    let formData = {
      customer_unique_r: token,
    };
    console.log(formData);
    
    this.apiService.post(this.apiConfig.customerDetails, formData).subscribe(
      (res: any) => {
        try {
          console.log(res);
          this.customerDetails = res.body.data;
        } catch (err) {
          console.log(err);
        }
      },
      (error: any) => {
        console.log(error);
        if (error.error instanceof ErrorEvent) {
          // client-side error
          this.toasterMessage = error.error.message;
        } else {
          // server-side error
          this.toasterMessage = error.error.data.message;
          console.log(this.toasterMessage)
        }
      }
    );
  }

  toggleView() {
    this.hideClearDB = !this.hideClearDB;
  }
  submitPaymentMode(formdata, target) {
    console.log(this.paymentModeForm)
    formdata.paymode_type = this.paymentMode;
    this.clearDBsubmit = true;
    console.log(this.paymentModeForm.invalid);
    formdata["customer_unique_r"] = this.customerDetails.customer_unique_ref;
    formdata["customername"] = this.customerDetails.customername;
    formdata["mobile"] = this.customerDetails.mobile;
    formdata["email"] = this.customerDetails.email;
    console.log(formdata);
    if (this.paymentModeForm.invalid) {
      return;
    }
    this.apiService.post(this.apiConfig.paymentmode, formdata).subscribe(
      (res: any) => {
        try {
          console.log(res);
          if (res.body.status == 200) {this.redirectToThankYouPage()};
        } catch (err) {
          console.log(err);
        }
      },
      (error: any) => {
        console.log(error);
        console.log(this.modal)
        if (error.error instanceof ErrorEvent) {
          // client-side error
          this.ModalMessage = error.error.message;
        } else {
          // server-side error
          this.ModalMessage = error.error.data.message;
          console.log(this.ModalMessage)
          this.modalService.open(this.modal,{centered:true,backdrop:false,size:'lg'})
        }
      }
    );
  }

  redirectToThankYouPage() {
    // Redirect to the thank you page
    window.location.href =
      "https://www.india.pringles.com/en/voucher-thank-you.html";
  }

  // clearDB(value) {
  //   // return

  //   let sendData = {
  //     pwd: value.password,
  //     program_id: value.program_id
  //   }
  //   // return
  //   this.apiService.get(this.apiConfig.clearDataBase + '?pwd=' + value.password)
  //     .subscribe((res: any) => {
  //       this.toggleView()
  //       this.modalRef.dismiss()
  //       this.toast.Success('Database cleared successfully')
  //       this.getPrograms();
  //     }, err => {

  //     })
  // }

  // callProgramConfigApi() {
  //   let apiUrl = this.apiConfig.programConfig;
  //   this.apiService.get(apiUrl).subscribe((res: any) => {
  //     if (res.status == 200) {
  //       //
  //       localStorage.setItem("has_beat", (res.data.has_beat));
  //       localStorage.setItem("has_channel", (res.data.has_channel));
  //       this.apiService.$beatResonseData.emit(res.data.has_beat);
  //       this.apiService.$channelResonseData.emit(res.data.has_channel);
  //     }
  //   }, (err) => {

  //   })
  // }
}
